import React from 'react';
import { useInView } from 'react-intersection-observer';

import GraphicPhone from '@assets/images/svg/phone.svg';
import GraphicSearch from '@assets/images/svg/search-blue.svg';
import GraphicTree from '@assets/images/svg/trees.svg';

import {
  ColumnWrapper,
  fromBottom,
  fromLeft,
  fromRight,
  ImageRow,
  ImageRowHeadingText,
  ImageRowInfoText,
  ImgRestrictWrap,
  MarketingWrapper,
} from './home.styled';

const MarketingRow: React.FunctionComponent = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
  });

  return (
    <MarketingWrapper>
      <ImageRow ref={ref}>
        <ColumnWrapper>
          <ImgRestrictWrap>
            <GraphicSearch
              css={inView ? { animation: `${fromLeft} 1s ease forwards` } : {}}
            />
          </ImgRestrictWrap>
          <ImageRowHeadingText>
            Finding a therapist
            <br />
            near me is easy.
          </ImageRowHeadingText>
          <ImageRowInfoText cMaxWidth={278}>
            Profiles and bios make it simple to find your ideal mental wellness
            partner before you book.
          </ImageRowInfoText>
        </ColumnWrapper>

        <ColumnWrapper>
          <ImgRestrictWrap>
            <GraphicTree
              css={
                inView ? { animation: `${fromBottom} 1s ease forwards` } : {}
              }
            />
          </ImgRestrictWrap>
          <ImageRowHeadingText>
            It’s not an office,
            <br />
            it’s an oasis.
          </ImageRowHeadingText>
          <ImageRowInfoText>
            Our beautiful space will help you start to feel okay the minute you
            walk through the door.
          </ImageRowInfoText>
        </ColumnWrapper>

        <ColumnWrapper>
          <ImgRestrictWrap>
            <GraphicPhone
              css={inView ? { animation: `${fromRight} 1s ease forwards` } : {}}
            />
          </ImgRestrictWrap>
          <ImageRowHeadingText>
            Our app makes
            <br />
            booking a snap!
          </ImageRowHeadingText>
          <ImageRowInfoText>
            Book, pay, check in, receive notes from your therapist and
            everything in between!
          </ImageRowInfoText>
        </ColumnWrapper>
      </ImageRow>
    </MarketingWrapper>
  );
};

export default MarketingRow;
